import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import env from '../config/env';

export const useGetOrderApi = (orderId: string): [OrderApi] => {
  const getOrderData = async (): Promise<Order> => {
    const { data: response } = await axios.get(`${env.apiUrl}/api/inner/v3/order/${orderId}`);
    return response;
  };

  const {
    isSuccess, data, isLoading, isError, isFetched,
  } = useQuery({
    queryKey: ['orderData'],
    queryFn: getOrderData,
    refetchOnWindowFocus: false,
    retry: 2,
    retryDelay: 2000,
  });

  return [{
    data, isSuccess, isLoading, isError, isFetched
  }];
};
