import { useState, FormEvent } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { Row, Col, Button, Text, Icon, Input } from '@geoverse/ui';
import { classNames, clearWhitespace } from '@geoverse/utils';
import { useScreenSize } from '@geoverse/hooks';

import { LanguageSwitcher } from '../../components/LanguageSwitcher';
import { CurrencyExchange } from '../../components/CurrencyExchange';
import { Calculation } from '../../components/Calculation';
import { ServiceAgreement } from '../../components/ServiceAgreement';

import { CookieAgreement } from '../../common-ui/components/CookieAgreement';
import { SupportHelp } from '../../common-ui/components/SupportHelp';
import { Spinner } from '../../common-ui/components/Spinner';

import { useGetOrderApi } from '../../hooks/useGetOrderApi';
import { GEOPayLogo } from '@geoverse/assets';
import { ReactComponent as OKXLogo } from '../../../assets/images/okx-logo.svg';
import env from '../../config/env';

const InvoiceWithdrawalPage = (): JSX.Element => {
  const { t } = useTranslation(['invoice', 'common']);
  const navigate = useNavigate();
  const { isSmallOrGreaterScreenSize, height } = useScreenSize();

  const orderId = useParams().order_id as string;

  const [{ data, isError, isLoading, isFetched }] = useGetOrderApi(orderId);

  const [cardNumber, setCardNumber] = useState<string>('');
  const [isCardNumberError, setIsCardNumberError] = useState<boolean>(false);

  const [isAgreementTaken, setIsAgreementTaken] = useState<boolean>(false);

  const setPaymentDetails = async (paymentDetails: PaymentDetails): Promise<PaymentDetailsResponse> => {
    const { data: response } = await axios.put(
      `${env.apiUrl}/api/inner/v3/order/${orderId}`,
      paymentDetails
    );

    return response;
  };

  const { mutate } = useMutation(setPaymentDetails, {
    onSuccess: (response): void => {
      setIsCardNumberError(false);
      if(response.redirect_url) {
        navigate('/success-payment', { replace: true, state: { redirectUrl: response.redirect_url } });
      }
    },
    onError: ({ response: { data } }): void => {
      if(data.errorCode === 'INVALID_CARD') {
        setIsCardNumberError(true);
      } else {
        setIsCardNumberError(false);
      }
    },
  });

  const sendCardDetails = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate({ toPaymentDetails: clearWhitespace(cardNumber) });
  }

  const changeCardNumberHandler = (e: FormEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLButtonElement;
    setCardNumber(target.value);
  }

  if (isLoading && !isFetched) return <Spinner />;

  if (isError) navigate('/something-wrong', { replace: true });

  return (
    <>
      <LanguageSwitcher />
      <div
        className={(classNames(
          'max-w-[668px] w-full',
          {'mt-auto mb-auto': isSmallOrGreaterScreenSize && height > 890 },
          {'mt-[274px] mb-[60px]': isSmallOrGreaterScreenSize && height < 891 },
          {'mt-[60px] mb-auto': !isSmallOrGreaterScreenSize && height >= 740 },
          {'mt-[172px] mb-[60px]': !isSmallOrGreaterScreenSize && height >= 681 && height <= 739 },
          {'mt-[194px] mb-[60px]': !isSmallOrGreaterScreenSize && height <= 680 }
        ))}
      >
        <Row align="center" justify="center" className="mb-4 mx-5">
          <form
            onSubmit={sendCardDetails}
            className="max-w-[628px] w-full px-4 sm:px-14 py-8 sm:py-12 rounded-2xl bg-page-light shadow-80"
          >
            <Row align="center" justify="between" className="mb-4 sm:mb-6">
              <Row>
                <GEOPayLogo
                  width={isSmallOrGreaterScreenSize ? 138 : 86}
                  height={isSmallOrGreaterScreenSize ? 32 : 20}
                />
                <OKXLogo
                  width={isSmallOrGreaterScreenSize ? 90 : 56}
                  height={isSmallOrGreaterScreenSize ? 32 : 20}
                  className={isSmallOrGreaterScreenSize ? "ml-9" : "ml-4"}
                />
              </Row>
              <Text variant={isSmallOrGreaterScreenSize ? 'headline' : 'lead'} weight="bold" color="inherit">
                {t('invoice:texts:sell')} {data?.from}
              </Text>
            </Row>
            <CurrencyExchange orderDetails={data} />
            <Row align="center" justify="between" className="mb-3.5 sm:mb-6">
              <Col flex="none" className="w-full">
                <Input.CardNumber
                  wrapperClassName="w-full"
                  label={t('invoice:texts:cardNumber')}
                  placeholder={t('invoice:placeholders:cardNumber')}
                  RightComponent={null}
                  LeftComponent={<Icon.CreditCard size={20} />}
                  error={isCardNumberError ? t('invoice:error:cardNumberInvalid') : undefined}
                  errorMessageVisible={isCardNumberError}
                  onChange={changeCardNumberHandler}
                  />
                {!isCardNumberError &&
                  <Text variant="small" className="mt-0.5 mx-1 text-primary-800">
                    {t('invoice:texts:onlyUkrainianCards')}
                  </Text>
                }
              </Col>
            </Row>
            <Calculation orderDetails={data} />
            <Row justify={isSmallOrGreaterScreenSize ? 'center' : 'start'} className="mb-4 sm:mb-6">
              <ServiceAgreement
                isAgreementTaken={isAgreementTaken}
                setIsAgreementTaken={setIsAgreementTaken}
              />
            </Row>
            <Row justify="center">
              <Button
                type="submit"
                className={`${isSmallOrGreaterScreenSize ? 'w-[386px]' : 'w-full' }`}
                size={isSmallOrGreaterScreenSize ? 'large' : 'medium'}
                disabled={!isAgreementTaken || !cardNumber}
              >
                {t('common:buttons:sell')}
              </Button>
            </Row>
          </form>
        </Row>
        <Row align="center" justify="center" className="mb-4 sm:mb-6 mx-5 sm:mx-0 flex-nowrap sm:flex-wrap">
          <SupportHelp />
        </Row>
      </div>
      <Row justify="center" className="grow-0">
        <CookieAgreement />
      </Row>
    </>
  );
};

export default InvoiceWithdrawalPage;
