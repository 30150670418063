import { FC, ReactNode } from 'react';
import { Col } from '@geoverse/ui';

interface LayoutProps {
  children?: ReactNode
}

const Layout: FC<LayoutProps> = ({ children }): JSX.Element => {
  return (
    <Col align="center" justify="center" className="h-full">
      {children}
    </Col>
  )
};

export default Layout;
