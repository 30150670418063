import { Container } from '../Container';

const Spinner = (): JSX.Element => (
  <Container
    extendClassNameContainer="max-h-[640px] h-[80vh]"
    isCenteredContent
    isShowCookieAgreement={false}
    isShowLanguageSwitcher={false}
  >
    <div className="sk-circle-fade">
      <div className="sk-circle-fade-dot" />
      <div className="sk-circle-fade-dot" />
      <div className="sk-circle-fade-dot" />
      <div className="sk-circle-fade-dot" />
      <div className="sk-circle-fade-dot" />
      <div className="sk-circle-fade-dot" />
      <div className="sk-circle-fade-dot" />
      <div className="sk-circle-fade-dot" />
      <div className="sk-circle-fade-dot" />
    </div>
  </Container>
);

export default Spinner;
