import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Row, Text, Button } from '@geoverse/ui';
import { useScreenSize } from '@geoverse/hooks';

import { Container } from '../../common-ui/components/Container';
import { ReactComponent as ErrorMessageSVG } from '../../../assets/images/error-massage.svg';

const SomethingWrongPage = (): JSX.Element => {
  const { t } = useTranslation(['common', 'invoice']);
  const { isSmallOrGreaterScreenSize } = useScreenSize();
  const navigate = useNavigate();

  return (
    <Container>
      <ErrorMessageSVG className="mb-6" />
      <Text
        variant={isSmallOrGreaterScreenSize ? 'headline' : 'lead'}
        weight="bold"
        color="inherit"
        className="mb-6"
        >
        {t('invoice:error:title')}
      </Text>
      <Text
        variant="body"
        align="center"
        color="inherit"
        className="mb-10 sm:mb-14 whitespace-pre-line"
        >
        {t('invoice:error:description')}
      </Text>
      <Row className={`${isSmallOrGreaterScreenSize ? 'w-[386px]' : 'w-full' }`}>
        <Button
          block
          size={isSmallOrGreaterScreenSize ? 'large' : 'medium'}
          onClick={(): void => navigate(-1)}
          >
          {t('common:buttons:goBack')}
        </Button>
      </Row>
    </Container>
  );
};

export default SomethingWrongPage;
