import { FC, ReactNode } from 'react';
import { Row, Col } from '@geoverse/ui';
import { classNames } from '@geoverse/utils';
import { CookieAgreement } from '../CookieAgreement';
import { SupportHelp } from '../SupportHelp';
import { LanguageSwitcher } from '../../../components/LanguageSwitcher';

interface ContainerProps {
  children: ReactNode;
  extendClassNameContainer?: string;
  isCenteredContent?: boolean;
  isShowCookieAgreement?: boolean;
  isShowLanguageSwitcher?: boolean;
}

const Container: FC<ContainerProps> = ({
  children,
  extendClassNameContainer = '',
  isCenteredContent = false,
  isShowCookieAgreement = true,
  isShowLanguageSwitcher = true,
}): JSX.Element => (
  <>
    {isShowLanguageSwitcher && <LanguageSwitcher />}
    <div className={classNames('mt-auto mb-auto max-w-[668px] w-full', `${extendClassNameContainer}`)}>
      <Row align="center" justify="center" className="mb-4 sm:mt-[128px] mt-12 mx-5">
        <div
          className={classNames(
            'max-w-[628px] w-full min-h-[250px] sm:min-h-[380px] px-4 sm:px-14 py-8 sm:py-12 rounded-2xl bg-page-light shadow-80',
            { 'flex justify-center': isCenteredContent }
          )}
        >
          <Col align="center" justify={isCenteredContent ? 'center' : 'between'}>
            {children}
          </Col>
        </div>
      </Row>
      <Row align="center" justify="center" className="mb-4 sm:mb-6 mx-5 sm:mx-0 flex-nowrap sm:flex-wrap">
        <SupportHelp />
      </Row>
    </div>
    {isShowCookieAgreement &&
      <Row justify="center" className="grow-0">
        <CookieAgreement />
      </Row>
    }
  </>
);

export default Container;
