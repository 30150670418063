import { useTranslation } from 'react-i18next';
import { classNames } from '@geoverse/utils';
import { useScreenSize } from '@geoverse/hooks';
import { Row, Text, Icon, Link } from '@geoverse/ui';

import { LanguageDropdown } from '../../common-ui/components/LanguageDropdown';

const LanguageSwitcher = (): JSX.Element => {
  const { i18n } = useTranslation(['invoice']);
  const { isMediumOrGreaterScreenSize } = useScreenSize();

  return (
    <div className={classNames(
      'absolute',
      `${isMediumOrGreaterScreenSize ? 'top-[32px] right-[80px]' : 'top-[16px] right-[20px]'}`
    )}>
      <LanguageDropdown
        Trigger={
          <Link>
            <Row align="center" gap="3" className="py-1 px-3">
              <Text weight="normal" color="inherit">
                {i18n.language.split('-')[0].toUpperCase()}
              </Text>
              <Icon.ChevronDown size={16} />
            </Row>
          </Link>
        }
      />
    </div>
  );
};

export default LanguageSwitcher;
