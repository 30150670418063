import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Row, Col, Divider } from '@geoverse/ui';
import { useScreenSize } from '@geoverse/hooks';

interface CalculationProps {
  orderDetails?: Order;
}

const Calculation: FC<CalculationProps> = ({ orderDetails }): JSX.Element => {
  const { t } = useTranslation(['invoice']);
  const { isSmallOrGreaterScreenSize } = useScreenSize();

  const calculationContent = (): JSX.Element => (
    <>
      <Col flex={4} gap={isSmallOrGreaterScreenSize ? '4' : '1'}>
        <Text
          variant={isSmallOrGreaterScreenSize ? 'lead' : 'body'}
          color="inherit"
        >
          {t('invoice:labels:rate', {
            coin: orderDetails?.from,
          })}
        </Text>
        <Text
          variant={isSmallOrGreaterScreenSize ? 'lead' : 'body'}
          color="inherit"
        >
          {t('invoice:labels:fee')}
        </Text>
        <Text
          variant={isSmallOrGreaterScreenSize ? 'lead' : 'body'}
          weight={isSmallOrGreaterScreenSize ? 'bold' : 'semibold'}
          color="inherit"
        >
          {t('invoice:labels:total')}
        </Text>
      </Col>
      <Col flex={1} align="end" gap={isSmallOrGreaterScreenSize ? '4' : '1'}>
        <Text
          variant={isSmallOrGreaterScreenSize ? 'lead' : 'body'}
          color="inherit"
        >
          {orderDetails?.freshRate ?? orderDetails?.rate}
        </Text>
        <Text
          variant={isSmallOrGreaterScreenSize ? 'lead' : 'body'}
          color="inherit"
        >
          {orderDetails?.fee.split(' ')[0]}
        </Text>
        <Text
          variant={isSmallOrGreaterScreenSize ? 'lead' : 'body'}
          weight={isSmallOrGreaterScreenSize ? 'bold' : 'semibold'}
          color="inherit"
        >
          {orderDetails?.toAmount}
        </Text>
      </Col>
      <Col gap={isSmallOrGreaterScreenSize ? '4' : '1'}>
        <Text
          variant={isSmallOrGreaterScreenSize ? 'lead' : 'body'}
          color="inherit"
        >
          {orderDetails?.to}
        </Text>
        <Text
          variant={isSmallOrGreaterScreenSize ? 'lead' : 'body'}
          color="inherit"
        >
          {orderDetails?.to}
        </Text>
        <Text
          variant={isSmallOrGreaterScreenSize ? 'lead' : 'body'}
          weight={isSmallOrGreaterScreenSize ? 'bold' : 'semibold'}
          color="inherit"
        >
          {orderDetails?.to}
        </Text>
      </Col>
    </>
  );

  return isSmallOrGreaterScreenSize ?
    (
      <Row className="mb-6">
        <Divider />
        <Row
          gap="4"
          className="py-6"
        >
          {calculationContent()}
        </Row>
        <Divider />
      </Row>
    ) : (
      <Row
        gap="4"
        className="mb-4 px-4 py-2 rounded-xl bg-primary-50 border border-primary-200"
      >
        {calculationContent()}
      </Row>
    );
};

export default Calculation;
