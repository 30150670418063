import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Button, Text } from '@geoverse/ui';
import { useScreenSize } from '@geoverse/hooks';
import { Container } from '../../common-ui/components/Container';

const NotFoundPage = (): JSX.Element => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { isSmallOrGreaterScreenSize } = useScreenSize();

  return (
    <Container>
      <Text
        variant={isSmallOrGreaterScreenSize ? 'h2' : 'h4'}
        weight="bold"
        color="gray"
        className="mb-2.5 sm:mb-6"
      >
        404
      </Text>
      <Text
        variant={isSmallOrGreaterScreenSize ? 'h5' : 'lead'}
        weight="bold"
        align="center"
        className="mb-4 sm:mb-6 text-gray-800 whitespace-pre-line"
      >
        {t('common:sorry_page_not_found')}
      </Text>
      <Row className="w-full">
        <Button
          block
          size={isSmallOrGreaterScreenSize ? 'large' : 'medium'}
          onClick={(): void => navigate(-1)}
          >
          {t('common:buttons:goBack')}
        </Button>
      </Row>
    </Container>
  );
};

export default NotFoundPage;
