import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

/* ========================================================================== */

/*                                   Locales                                  */

/* ========================================================================== */
import commonEn from './translation/en/common.json';
import commonUIEn from './translation/en/common-ui.json';
import invoiceEn from './translation/en/invoice.json';

import commonRu from './translation/ru/common.json';
import commonUIRu from './translation/ru/common-ui.json';
import invoiceRu from './translation/ru/invoice.json';

import commonUk from './translation/uk/common.json';
import commonUIUk from './translation/uk/common-ui.json';
import invoiceUk from './translation/uk/invoice.json';

const supportedLngs = ['en', 'en-GB', 'en-US', 'uk', 'ru'];

const detectionOptions = {
  order: ['cookie', 'localStorage', 'sessionStorage', 'navigator', 'querystring', 'htmlTag', 'path', 'subdomain']
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    debug: process.env.NODE_ENV !== 'production',
    detection: detectionOptions,
    fallbackLng: 'en',
    supportedLngs: supportedLngs,
    ns: ['invoice'],
    defaultNS: 'invoice',
    returnNull: false,
    nsSeparator: ':',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        common: commonEn,
        commonUi: commonUIEn,
        invoice: invoiceEn,
      },
      ru: {
        common: commonRu,
        commonUi: commonUIRu,
        invoice: invoiceRu,
      },
      uk: {
        common: commonUk,
        commonUi: commonUIUk,
        invoice: invoiceUk,
      },
    },
  });

export default i18n;
