import { useState, FC } from 'react';
import { classNames } from '@geoverse/utils';
import { useTranslation } from 'react-i18next';
import { Text, Link, Button } from '@geoverse/ui';

const CookieAgreement: FC = () => {
  const { t } = useTranslation(['commonUi']);

  const [isAgree, setIsAgree] = useState<boolean>(false);
  return (
    <div
      className={
        classNames(
          'mx-5 sm:mx-5 px-4 h-[88px] sm:h-[48px] shadow-60',
          'max-w-[522px] w-full sm:none',
          'flex items-center justify-center bg-white rounded-tl-xl rounded-tr-xl',
          { invisible: isAgree },
        )
      }
    >
      <Text variant="body" color="inherit">
        {t('commonUi:cookie:title')}
        <Link
          className="underline !font-medium"
          rel="noreferrer"
          href="https://geo-pay.net/legal/cookie-policy"
          target="_blank"
        >
          {t('commonUi:cookie:link')}
        </Link>
      </Text>
      <Button
        className="ml-4"
        size="small"
        onClick={(): void => setIsAgree(true)}
      >
        {t('commonUi:cookie:agree_action')}
      </Button>
    </div>
  );
};

export default CookieAgreement;
