import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Row, Col, Icon } from '@geoverse/ui';
import { useScreenSize } from '@geoverse/hooks';

interface CurrencyExchangeProps {
  orderDetails?: Order;
}

const CurrencyExchange: FC<CurrencyExchangeProps> = ({ orderDetails }): JSX.Element => {
  const { t } = useTranslation(['invoice']);
  const { isSmallOrGreaterScreenSize } = useScreenSize();

  const youSellBlock = (): JSX.Element => (
    <Col>
      <Text variant={isSmallOrGreaterScreenSize ? 'body' : 'small'} className="mb-1" color="inherit">
        {t('invoice:texts:you_sell')}
      </Text>
      <Text variant="lead" weight="bold" color="inherit">
        {orderDetails?.fromAmount ?? 0} {orderDetails?.from}
      </Text>
    </Col>
  );

  const youReciveBlock = (): JSX.Element => (
    <Col>
      <Text variant={isSmallOrGreaterScreenSize ? 'body' : 'small'} className="mb-1" color="inherit">
        {t('invoice:texts:you_receive')}
      </Text>
      <Text variant="lead" weight="bold" color="inherit">
        {orderDetails?.toAmount ?? 0} {orderDetails?.to}
      </Text>
    </Col>
  );

  return isSmallOrGreaterScreenSize ?
    (
      <Row
        align="center"
        justify="center"
        className="mx-16 mb-6 px-2 py-2 bg-gray-200 rounded-[4px]"
      >
        {youSellBlock()}
        <Col className="mx-9">
          <Icon.ArrowsRightLeft />
        </Col>
          {youReciveBlock()}
      </Row>
    ) : (
      <Col
        align="start"
        justify="center"
        className="mb-3.5 px-4 py-1 bg-gray-200 rounded-[4px]"
      >
        <Row align="center">
          <Icon.CardDeposit size={24} className="mr-4" />
          <div>{youSellBlock()}</div>
        </Row>
        <Row align="center">
          <Icon.CardWithdraw size={24} className="mr-4" />
          <div>{youReciveBlock()}</div>
        </Row>
      </Col>
    );
};

export default CurrencyExchange;
