import { useTranslation } from 'react-i18next';
import { Text, Link, Icon } from '@geoverse/ui';

const SupportHelp = (): JSX.Element => {
  const { t } = useTranslation(['commonUi']);

  return (
    <>
      <Icon.Lifebuoy size={24} className="mr-4 !text-gray-700 shrink-0" />
      <Text variant="body" color="inherit" weight="semibold">
        {t('commonUi:support_help:message')}
        <Link
          variant="text"
          className="underline !font-normal"
          rel="noreferrer"
          href="mailto:support@geo-pay.net"
        >
          support@geo-pay.net
        </Link>
      </Text>
    </>
  );
};

export default SupportHelp;
