import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Text, Checkbox, Link } from '@geoverse/ui';
import { useScreenSize } from '@geoverse/hooks';

interface ServiceAgreementProps {
  isAgreementTaken: boolean;
  setIsAgreementTaken: (val: boolean) => void;
}

const ServiceAgreement: FC<ServiceAgreementProps> = ({ isAgreementTaken, setIsAgreementTaken }): JSX.Element => {
  const { isSmallOrGreaterScreenSize } = useScreenSize();

  return (
    <Checkbox
      inputClassName="mr-[4px]"
      onClick={(): void => setIsAgreementTaken(!isAgreementTaken)}
    >
      <Text variant={isSmallOrGreaterScreenSize ? 'body' : 'small'} color="inherit">
        <Trans i18nKey="commonUi:service_agreement">
          <span>I agree with the</span>
          <Link
            size={isSmallOrGreaterScreenSize ? 'default' : 'small'}
            variant="text"
            className="underline"
            rel="noreferrer"
            href="https://geo-pay.net/legal/terms-of-use"
            target="_blank"
          >
            service terms
          </Link>
            <span>and the</span>
          <Link
            size={isSmallOrGreaterScreenSize ? 'default' : 'small'}
            variant="text"
            className="underline"
            rel="noreferrer"
            href="https://geo-pay.net/legal/privacy-policy"
            target="_blank"

          >
            privacy policy
          </Link>
        </Trans>
      </Text>
    </Checkbox>
  );
};

export default ServiceAgreement;
