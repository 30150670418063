import {
  Button,
  ButtonSize,
  Dropdown,
  Icon,
} from '@geoverse/ui';
import { classNames } from '@geoverse/utils';
import { useTranslation } from 'react-i18next';

/* ========================================================================== */
/*                                    Props                                   */
/* ========================================================================== */

export type LanguageDropdownProps = {
  Trigger?: React.ReactElement;
  triggerSize?: ButtonSize;
};

const LanguageDropdown = ({
  Trigger,
  triggerSize = 'medium',
}: LanguageDropdownProps) => {
  const { i18n } = useTranslation();

  const languages: { title: string; name: string }[] = [
    {
      title: 'English',
      name: 'en',
    },
    {
      title: 'Русский',
      name: 'ru',
    },
    {
      title: 'Українська',
      name: 'uk',
    },
  ];

  return (
    <Dropdown
      placement="bottomRight"
      Trigger={
        Trigger ?? (
          <Button
            size={triggerSize}
            variant="ghost"
            className={classNames('!min-w-0', {
              '!px-3': triggerSize === 'small',
            })}
            RightComponent={<Icon.ChevronDown />}
          >
            {i18n.language.split('-')[0].toUpperCase()}
          </Button>
        )
      }
    >
      {languages.map((language, index) => (
        <Dropdown.Item
          key={`${language.name}-${index}`}
          selected={i18n.language === language.name}
          onClick={() => i18n.changeLanguage(language.name)}
        >
          {language.title}
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};

export default LanguageDropdown;
