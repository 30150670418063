import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import { InvoiceWithdrawalPage } from '../pages/InvoiceWithdrawalPage';
import { SomethingWrongPage } from '../pages/SomethingWrongPage';
import { SuccessPaymentPage } from '../pages/SuccessPaymentPage';
import { NotFoundPage } from '../pages/NotFoundPage';

const AppRoutes: FC = () => {

  return (
    <Routes>
      <Route path="order/:order_id" element={<InvoiceWithdrawalPage />} />
      <Route path="something-wrong" element={<SomethingWrongPage />} />
      <Route path="success-payment" element={<SuccessPaymentPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
